.image-grid {
  width: 100%;
  max-width: 1310px;
  margin: 0 auto;
  overflow: hidden;
  padding: 10px 5px 0;
  text-decoration: none;
}

.image__cell {
  float: left;
  position: relative;
}

.image--basic {
  padding: 0 5px;
}

.basic__img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.image__cell.is-collapsed .image--basic {
  cursor: pointer;
}

.image__cell.is-expanded .image--expand {
  max-height: 1000px;
  margin-bottom: 10px;
}

.image--expand {
  position: relative;
  box-sizing: content-box;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out, margin-bottom 0.1s 0.2s;
  margin: 11px 5px 0px;
}

.image--large {
  max-width: 100%;
  height: auto;
  display: block;
  padding: 40px;
  margin: 0 auto;
  box-sizing: border-box;
}

@media only screen and (max-width: 530px) {
  .image__cell {
    width: 50%;
  }

  .image__cell:nth-of-type(2n + 2) .image--expand {
    margin-left: -100%;
    clear: left;
  }

  .image__cell:nth-of-type(2n + 3) {
    clear: left;
  }

  .image--expand {
    width: 200%;
  }
}

.image__cell.is-collapsed .arrow--up {
  display: block;
  height: 7px;
  width: 100%;
}

.image__cell.is-expanded .arrow--up {
  display: block;
  border-top: 8px solid #f4f7f8;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  height: 0;
  width: 0;
  margin: -1px auto 0;
}

.image__cell.is-expanded .arrow--up:before {
  content: '';
  position: absolute;
  height: 11px;
  width: 11px;
  -webkit-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 55% -13%;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  background-color: #f4f7f8;

  border-top: 1px solid #7babcb;
  border-right: 1px solid #7babcb;
}

.image__cell.is-expanded.isSandboxMode .arrow--up {
  border-top: 8px solid #f4f7f8 !important;
}

.image__cell.is-expanded.isSandboxMode .arrow--up:before {
  background-color: #f4f7f8 !important;

  border-top: 1px solid #9ab7ca !important;
  border-right: 1px solid #9ab7ca !important;
}

@media only screen and (min-width: 531px) {
  .image__cell {
    width: 50%;
  }

  .image__cell:nth-of-type(2n + 2) .image--expand {
    margin-left: -100%;
    padding-left: 5px;
  }

  .image__cell:nth-of-type(2n + 3) {
    clear: left;
  }

  .image--expand {
    width: 200%;
  }
}
