.section-create-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #444444 !important;
}

.section-create-description {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #777777 !important;
}

.section-create-link {
  font-size: 13px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.23 !important;
  letter-spacing: normal !important;
  color: #999999 !important;
  margin-top: 40px !important;
}

.pos-detail-title {
  font-family: Montserrat !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}
.f-22 {
  font-size: 22px !important;
}

.f-72 {
  font-size: 72px !important;
}

.color7 {
  color: #777777 !important;
}

.color6 {
  color: #666666 !important;
}

.color4 {
  color: #444444 !important;
}

.listComponentText {
  font-size: 18px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.semiBold {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #444444 !important;
}

.text-field-bold {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.w600 {
  font-weight: 600 !important;
}
.montserrat {
  font-family: 'Montserrat', sans-serif !important;
}
.italic {
  font-style: italic !important;
}

.uppercase {
  text-transform: uppercase;
}

.selected-flag {
  outline: none;
}

.select__hover-active {
  background: yellow;
}
